<template>
  <div class="fan-portrait" v-loading>
    <div class="box_header">
      <DataCenterHeader @selectAccountData="getFans"></DataCenterHeader>
    </div>

    <div class="check-account" v-show="isShowCheck" ref="checkAccount">
      <i class="el-icon-sort"></i>
      <span>账号切换</span>
    </div>

    <div class="content_box">
      <div class="content-title">粉丝画像</div>

      <div v-if="currentAccount.follower_count < 300" class="no-data">
        <img src="../../../../assets/img/image/no_data_default.png" alt="" />
        <span>粉丝数>300可查看粉丝画像，积极创作作品吸收更多粉丝吧</span>
        <span>粉丝数首次达到300，数据次日计算产出</span>
      </div>

      <div v-else class="content-data">
        <div v-for="item in chartConfigs" :key="item.id" :class="['data-item', item.size]">
          <template v-if="item.type === 'chart'">
            <template v-if="fans[item.id]">
              <span class="item-title">{{ item.name }}</span>

              <template v-if="fans[item.id].length > 0">
                <div class="chart" :ref="item.ref"></div>
              </template>

              <template v-else>
                <div class="item-no-data">
                  <img src="../../../../assets/img/image/no_data_default.png" alt="" />
                  <div class="no-default-data">暂无数据</div>
                </div>
              </template>
            </template>
          </template>

          <template v-else-if="item.type === 'multiple-chart'">
            <template v-if="fans[item.id]">
              <span class="item-title">{{ item.name }}</span>
              <div class="chart" v-if="fans[item.id].length > 0">
                <div v-for="refItem in item.ref" :key="refItem" class="chart-child" :ref="refItem"></div>
              </div>

              <template v-else>
                <div class="item-no-data">
                  <img src="../../../../assets/img/image/no_data_default.png" alt="" />
                  <div class="no-default-data">暂无数据</div>
                </div>
              </template>
            </template>
          </template>

          <template v-else-if="item.type === 'table'">
            <template v-if="fans[item.id]">
              <span class="item-title">{{ item.name }}</span>
              <div class="chart padding-inline-20" ref="interestChart" v-if="fans[item.id].length > 0">
                <el-table height="350" :data="item.tableData" style="width: 100%">
                  <el-table-column
                    :prop="tableItem.prop"
                    :label="tableItem.label"
                    v-for="tableItem in item.tableConfigs"
                    :key="tableItem.prop"
                    :width="tableItem.width"
                  >
                  </el-table-column>
                </el-table>
              </div>

              <template v-else>
                <div class="item-no-data">
                  <img src="../../../../assets/img/image/no_data_default.png" alt="" />
                  <div class="no-default-data">暂无数据</div>
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataCenterHeader from "@/components/dataCenter/DataCenterHeader.vue";
import "@/utils/china.js";

// 地图初始化数据时使用
const province = [
  "台湾",
  "河北",
  "山西",
  "内蒙古",
  "辽宁",
  "吉林",
  "黑龙江",
  "江苏",
  "浙江",
  "安徽",
  "福建",
  "江西",
  "山东",
  "河南",
  "湖北",
  "湖南",
  "广东",
  "广西",
  "海南",
  "四川",
  "贵州",
  "云南",
  "西藏",
  "陕西",
  "甘肃",
  "青海",
  "宁夏",
  "新疆",
  "北京",
  "天津",
  "上海",
  "重庆",
  "香港",
  "澳门",
  "南海诸岛",
];
export default {
  data() {
    return {
      isShowCheck: false,
      accountList: [], // 账号列表
      fans: {}, // 粉丝画像数据
      currentAccount: {},
      charts: [],
      chartConfigs: [
        {
          id: "gender_distributions",
          name: "性别分布",
          type: "chart",
          data: [],
          ref: "genderChart",
          size: "small",
        },
        {
          id: "age_distributions",
          name: "年龄分布",
          type: "chart",
          data: [],
          ref: "ageChart",
          size: "small",
        },
        {
          id: "geographical_distributions",
          name: "地域分布",
          type: "multiple-chart",
          data: [],
          ref: ["geographicalChart1", "geographicalChart2"],
          size: "full-columns",
        },
        {
          id: "device_distributions",
          name: "设备分布",
          type: "chart",
          data: [],
          ref: "deviceChart",
          size: "normal",
        },
        {
          id: "interest_distributions",
          name: "粉丝兴趣分布",
          type: "table",
          tableData: [],
          tableConfigs: [
            {
              prop: "name",
              label: "兴趣",
              width: "auto",
            },
            {
              prop: "value",
              label: "占比",
              width: "80",
            },
          ],
          data: [],
          ref: "interestChart",
          size: "normal",
        },
        {
          id: "comment",
          name: "粉丝评论热词",
          type: "table",
          tableData: [],
          tableConfigs: [
            {
              prop: "name",
              label: "评论热词",
            },
            {
              prop: "value",
              label: "占比",
            },
          ],
          data: [],
          ref: "commentChart",
          size: "normal",
        },
        {
          id: "favourite",
          name: "粉丝喜好关键词",
          type: "table",
          tableData: [],
          tableConfigs: [
            {
              prop: "name",
              label: "喜好关键词",
            },
            {
              prop: "value",
              label: "占比",
            },
          ],
          data: [],
          ref: "favouriteChart",
          size: "normal",
        },
        {
          id: "active_days_distributions",
          name: "粉丝活跃天数分布",
          type: "chart",
          data: [],
          ref: "activeChart",
          size: "small",
        },
        {
          id: "flow_contributions",
          name: "粉丝流量贡献",
          type: "chart",
          data: [],
          ref: "flowChart",
          size: "small",
        },
      ],
    };
  },
  mounted() {},
  components: {
    DataCenterHeader,
  },
  methods: {
    // 获取粉丝画像数据
    getFans(account) {
      this.currentAccount = account;

      const params = { id: account.id };
      this.$httpStudent.axiosGetBy(this.$api.dataCenterFans, params, (res) => {
        if (res.code === 200) {
          this.fans = {
            gender_distributions: res.data.gender_distributions,
            age_distributions: res.data.age_distributions,
            geographical_distributions: res.data.geographical_distributions,
            device_distributions: res.data.device_distributions,
            interest_distributions: res.data.interest_distributions,
            comment: res.data.comment,
            favourite: res.data.favourite,
            active_days_distributions: res.data.active_days_distributions,
            flow_contributions: res.data.flow_contributions.map((item) => {
              if (item.flow == "vv") item.name = "粉丝观看pv";
              else if (item.flow == "like_cnt") item.name = "点赞";
              else if (item.flow == "comment_cnt") item.name = "评论";
              else if (item.flow == "share_video_cnt") item.name = "分享";

              return item;
            }),
          };

          for (let key in this.fans) {
            const chartObj = this.chartConfigs.find((item) => item.id === key);
            this.$set(chartObj, "data", this.fans[key]);
          }

          this.$nextTick(() => this.setChartOption());
        }
      });
    },

    // set charts options
    setChartOption() {
      this.setGenderChartOption(); // 设置性别分布
      this.setAgeChartOption(); // 设置年龄分布
      this.setGeographicalChartOption(); // 设置地域分布
      this.setDeviceChartOption(); // 设置设备分布
      this.setInterestChartOption(); // 设置粉丝兴趣分布
      this.setCommentChartOption(); // 设置粉丝评论热词分布
      this.setFavoriteChartOption(); // 设置喜好关键词分布
      this.setActiveChartOption(); // 设置活跃天数分布
      this.setFlowChartOption(); // 设置粉丝浏览贡献分布

      window.addEventListener("resize", this.setChartSize);
    },

    setChartSize() {
      this.charts.forEach((item) => {
        item.resize();
      });
    },

    getPercentData(key, nameKey, valueKey, sort, formatPercent = true) {
      const unprocessData = this.fans[key];
      const data = [];
      const sum = unprocessData.reduce((prev, item) => prev + item[valueKey], 0);

      unprocessData.forEach((item) => {
        const value = ((item[valueKey] * 100) / sum).toFixed(2);
        data.push({
          name: item[nameKey],
          value: `${isNaN(value) ? "0" : value}${formatPercent ? "%" : ""}`,
        });
      });

      if (sort) {
        data.sort((a, b) => {
          const number1 = parseFloat(a.value.replace("%", ""));
          const number2 = parseFloat(b.value.replace("%", ""));
          return number2 - number1;
        });
      }
      return data;
    },

    setGenderChartOption() {
      if (!this.$refs.genderChart || this.$refs.genderChart.length == 0) return;

      const data = this.getPercentData("gender_distributions", "item", "value", true, false);
      data.forEach((item) => {
        item.name = item.name == "1" ? "男性" : "女性";
      });

      const chart = this.$echarts.init(this.$refs.genderChart[0]);
      const options = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `
              <div style="color: '#86909C';">${params.name}</div>
              <div style="color: #000;">占比：<span style="color: ${params.color}; font-size: 20px;">${params.value || 0}%</span></div>
            `;
          },
        },
        legend: {
          show: true,
          orient: "horizontal",
          left: "right",
          align: "left",
          itemWidth: 14,
          itemHeight: 14,
          icon: "circle",
        },
        series: [
          {
            name: "性别",
            type: "pie",
            radius: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
              formatter: "{d|{d}}%\n{b|{b}}",
              edgeDistance: 30,
              lineHeight: 24,
              rich: {
                d: {
                  fontSize: "20px",
                  color: "#252632",
                },
                b: {
                  fontSize: "12px",
                  color: "rgba(37,38,50,0.6)",
                },
              },
              color: "rgba(37, 38, 50, 0.6)",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length: 20,
              length2: 30,
              maxSurfaceAngle: 80,
            },
            data: data,
          },
        ],
      };
      chart.setOption(options);

      this.charts.push(chart);
    },

    setAgeChartOption() {
      if (!this.$refs.ageChart || this.$refs.ageChart.length === 0) return;

      const data = this.getPercentData("age_distributions", "item", "value", false, false);

      const chart = this.$echarts.init(this.$refs.ageChart[0]);
      const options = {
        tooltip: {
          trigger: "axis", // 悬停时触发
          formatter: function (params) {
            return `
              <div style="color: '#86909C';">${params[0].name}</div>
              <div style="color: #000;">占比：<span style="color: ${params[0].color}; font-size: 20px;">${
              params[0].value || 0
            }%</span></div>
            `;
          },
          axisPointer: { type: "shadow" }, // 指示器类型为阴影
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.name),
          axisLabel: { color: "#86909C" },
          axisLine: { lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "86909C", formatter: "{value}%" },
          axisLine: { show: true, lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
        },
        series: [
          {
            name: "数据",
            type: "bar",
            data: data.map((item) => item.value),
            itemStyle: {
              color: "#594fee",
              borderRadius: [4, 4, 0, 0],
            },
            barWidth: 24,
          },
        ],
      };
      chart.setOption(options);

      this.charts.push(chart);
    },

    setGeographicalChartOption() {
      const data1 = this.fans["geographical_distributions"].map((item) => {
        return { name: item.item, value: item.value };
      });

      const data2 = this.getPercentData("geographical_distributions", "item", "value", true, false);
      this.setGeographicalChartOption1(data1);
      this.setGeographicalChartOption2(data2);
    },

    setGeographicalChartOption1(data) {
      if (!this.$refs.geographicalChart1 || this.$refs.geographicalChart1.length == 0) return;

      province.forEach((item) => {
        const findIndex = data.findIndex((obj) => obj.name == item);
        if (findIndex === -1) {
          data.push({
            name: item,
            value: 0,
          });
        }
      });

      const chart = this.$echarts.init(this.$refs.geographicalChart1[0]);
      const options = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `
              <div style="color: #000;">${params.name}<span style="color: #252632; font-size: 20px; margin-left: 10px;">${
              params.value || 0
            }</span></div>
            `;
          },
        },
        legend: {
          show: false,
        },
        visualMap: {
          selectedMode: false,
          seriesIndex: 0,
          min: 0,
          max: 100000000,
          left: 20,
          bottom: 20,
          type: "piecewise",
          align: "left",
          itemWidth: 22,
          itemHeight: 14,
          textStyle: {
            color: "#000000",
            fontSize: 14,
            lineHeight: 20,
          },
          color: "green",
          pieces: [
            {
              gte: 166,
              color: "#24cce7",
              label: ">166",
            },
            {
              gte: 124,
              lte: 166,
              color: "#48b8ed",
              label: "124-166",
            },
            {
              gte: 83,
              lte: 124,
              color: "#7fb9f2",
              label: "83-124",
            },
            {
              gte: 41,
              lte: 83,
              color: "#bdd1f7",
              label: "41-83",
            },
            {
              lte: 41,
              color: "#e0e4fc",
              label: "<41",
            },
          ],
        },
        geo: {
          map: "china",
          label: {
            emphasis: {
              show: true,
              color: "#fff",
            },
          },
          select: {
            show: false,
          },
          zoom: 1.2,
          roam: false, // 地图缩放拖拽
          itemStyle: {
            normal: {
              areaColor: "#9CC2FF", // 地图省份的背景颜色
              borderColor: "#ffffff",
              borderWidth: 1,
            },
            emphasis: {
              areaColor: "#594fee",
            },
          },
        },
        series: [
          {
            selectedMode: false,
            name: "地域分布",
            type: "map",
            map: "china",
            geoIndex: 0,
            data: data,
          },
        ],
      };
      chart.setOption(options);
      this.charts.push(chart);
    },

    setGeographicalChartOption2(data) {
      if (!this.$refs.geographicalChart2 || this.$refs.geographicalChart2.length == 0) return;
      if (data.length > 10) data = data.slice(0, 10);

      const chart = this.$echarts.init(this.$refs.geographicalChart2[0]);
      const options = {
        grid: {
          left: "20%", // 增加左侧留白
          right: "10%", // 如果右侧留白需要调整
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            return `
              <div style="color: '#86909C';">${params[0].name}</div>
              <div style="color: #000;">占比：<span style="color: ${params[0].color}; font-size: 20px;">${
              params[0].value || 0
            }%</span></div>
            `;
          },
        },
        xAxis: [
          {
            type: "value",
            show: true,
            position: "top",
            splitLine: { show: false },
            axisLabel: {
              formatter: function (value, index) {
                const inner = index === 0 ? "排名   地区 " : "";
                return inner;
              },
              align: "right",
              fontSize: 14,
              fontWeight: 400,
              color: "#000",
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: data.map((item) => item.name),
            axisLabel: {
              color: "#000",
              formatter: function (value, index) {
                return `${index + 1}     ${value}`;
              },
            },
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "地域分布",
            type: "bar",
            z: 3,
            label: {
              position: "right",
              show: true,
              formatter: function (params) {
                return `${params.value}%`;
              },
            },
            itemStyle: {
              borderRadius: [5, 5, 5, 5],
              color: "#6B9EFF",
            },
            barWidth: 12,
            data: data,
          },
        ],
      };
      chart.setOption(options);

      this.charts.push(chart);
    },

    setDeviceChartOption() {
      if (!this.$refs.deviceChart || this.$refs.deviceChart.length == 0) return;

      const data = this.getPercentData("device_distributions", "item", "value", false, false);
      const chart = this.$echarts.init(this.$refs.deviceChart[0]);
      const options = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `
              <div style="color: '#86909C';">${params.name}</div>
              <div style="color: #000;">占比：<span style="color: ${params.color}; font-size: 20px;">${params.value}%</span></div>
            `;
          },
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "设备",
            type: "pie",
            radius: ["40%", "50%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: "outside",
              formatter: "{d|{d}}%\n{b|{b}}",
              edgeDistance: 30,
              lineHeight: 24,
              rich: {
                d: {
                  fontSize: "20px",
                  color: "#252632",
                },
                b: {
                  fontSize: "12px",
                  color: "rgba(37,38,50,0.6)",
                },
              },
              color: "rgba(37, 38, 50, 0.6)",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length: 40,
              length2: 30,
            },
            data: data,
          },
        ],
      };
      chart.setOption(options);

      this.charts.push(chart);
    },

    setInterestChartOption() {
      const data = this.getPercentData("interest_distributions", "item", "value", true, true);

      const chartObj = this.chartConfigs.find((item) => item.id === "interest_distributions");
      this.$set(chartObj, "tableData", data);
    },

    setCommentChartOption() {
      const data = this.getPercentData("comment", "keyword", "hot_value", true, true);

      const chartObj = this.chartConfigs.find((item) => item.id === "comment");
      this.$set(chartObj, "tableData", data);
    },

    setFavoriteChartOption() {
      const data = this.getPercentData("favourite", "keyword", "hot_value", true, true);

      const chartObj = this.chartConfigs.find((item) => item.id === "favourite");
      this.$set(chartObj, "tableData", data);
    },

    setActiveChartOption() {
      if (!this.$refs.activeChart || this.$refs.activeChart.length == 0) return;
      const data = this.getPercentData("active_days_distributions", "item", "value", false, false);

      const chart = this.$echarts.init(this.$refs.activeChart[0]);
      const options = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `
              <div style="color: '#86909C';">${params.name}</div>
              <div style="color: #000;">占比：<span style="color: ${params.color}; font-size: 20px;">${params.value}%</span></div>
            `;
          },
        },
        legend: {
          show: true,
          orient: "horizontal",
          bottom: 20,
          left: "center",
          align: "left",
          itemWidth: 10,
          itemHeight: 10,
          icon: "circle",
        },
        series: [
          {
            name: "活跃天数分布",
            type: "pie",
            avoidLabelOverlap: true, // 重叠位置是否显示
            radius: ["40%", "50%"],
            label: {
              show: true,
              position: "outside",
              formatter: "{d|{d}}%\n{b|{b}}",
              edgeDistance: 30,
              lineHeight: 24,
              color: "rgba(37, 38, 50, 0.6)",
              rich: {
                d: {
                  fontSize: "20px",
                  color: "#252632",
                },
                b: {
                  fontSize: "12px",
                  color: "rgba(37,38,50,0.6)",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length: 20,
              length2: 30,
            },
            data: data,
          },
        ],
      };
      chart.setOption(options);

      this.charts.push(chart);
    },

    setFlowChartOption() {
      if (!this.$refs.flowChart || this.$refs.flowChart.length === 0) return;

      const flowData = this.fans.flow_contributions;
      const data = [];
      const sum = flowData.reduce((prev, item) => prev + item.fans_sum, 0);
      flowData.forEach((item) => {
        data.push({
          name: item.name,
          value: item.fans_sum,
        });
      });

      const chart = this.$echarts.init(this.$refs.flowChart[0]);
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
          formatter: function (params) {
            const value = isNaN(((params[0].value * 100) / sum).toFixed(2)) ? 0 : ((params[0].value * 100) / sum).toFixed(2);
            return `
              <div style="color: '#86909C';">${params[0].name}</div>
              <div style="color: #000;">占比：<span style="color: ${params[0].color}; font-size: 20px;">${value}%</span></div>
            `;
          },
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.name),
          axisLabel: { color: "#86909C" },
          axisLine: { lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "#86909C" },
          axisLine: { show: true, lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
        },
        series: [
          {
            name: "数据",
            type: "bar",
            data: data.map((item) => item.value),
            itemStyle: {
              color: "#594fee",
              borderRadius: [4, 4, 0, 0],
            },
            barWidth: 24,
          },
        ],
      };
      chart.setOption(options);

      this.charts.push(chart);
    },
  },

  destroyed() {
    window.removeEventListener("resize", this.setChartSize);
  },
};
</script>

<style scoped lang="scss">
.fan-portrait {
  .box_header {
  }

  .check-account {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 170px;
    height: 80px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;

    i {
      transform: rotate(90deg);
    }

    span,
    i {
      cursor: pointer;
    }
  }

  .content_box {
    margin-top: 20px;
    min-height: calc(100vh - 232px);
    background-color: #ffffff;
    padding: 24px;
    box-sizing: border-box;

    .content-title {
      font-family: PingFang SC, PingFang SC;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 24px;
      color: #252632;
      line-height: 28px;
    }

    .no-data {
      margin-top: 194px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: none;

      img {
        width: 220px;
        height: 155px;
        object-fit: cover;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: rgba(28, 31, 35, 0.35);
        font-family: "PingFang SC, PingFang SC";
      }
    }

    .content-data {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      overflow: hidden;

      .no-default-data {
        padding: 24px;
        color: rgba(37, 38, 50, 0.6);
        font-size: 16px;
        font-weight: 400;
      }

      .data-item {
        height: 433px;
        box-sizing: border-box;
        background-color: #fafafb;
        border-radius: 4px;
        position: relative;
        // overflow: hidden;

        .item-no-data {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &.small {
          height: 412px;
        }

        &.full-columns {
          grid-column: 1 / -1;
        }

        .item-title {
          position: absolute;
          left: 20px;
          top: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #161823;
          z-index: 10;
        }

        .chart {
          width: 100%;
          height: 100%;
          padding-top: 20px;
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: black;

          &-child {
            width: 50%;
            height: 100%;
            position: relative;
            // background: black;

            // &:nth-child(2) {
            //   // background: pink;
            // }
          }

          .label-notation {
            position: absolute;
            display: flex;
            gap: 18px;
            left: 50%;
            top: 40px;
            transform: translateX(42px);

            span {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              line-height: 16px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }

          &.padding-inline-20 {
            padding-inline: 20px;

            ::v-deep .el-table {
              margin-top: 40px;
            }

            ::v-deep .el-table th.el-table__cell {
              background-color: #efeff0;
            }

            ::v-deep .el-table td.el-table__cell,
            .el-table th.el-table__cell.is-leaf {
              border: none;
            }

            ::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
              width: 4px;
              /* 滚动条的宽度 */
            }

            ::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {
              background-color: #efeff0;
              /* 滚动条的颜色 */
              border-radius: 20px;
              /* 滚动条的圆角半径 */
            }

            ::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
              background-color: #efeff0;
              /* 滚动条的悬停颜色 */
            }

            ::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {
              background-color: #fff;
              /* 滚动条的轨道颜色 */
              border-radius: 20px;
              /* 滚动条轨道的圆角半径 */
            }
          }
        }
      }
    }
  }
}
</style>